<template>
    <div>
        <div class="columns">
            <div class="column"><h2 class="title">Member Training Report</h2></div>
            <div class="column"><button class="button mr-4" :class="{'is-success' : showNursesOnly == 1}" @click="toggleShowNurses()">Show Nurses Only</button>
                <button class="button mr-4" :class="{'is-success' : showAllMembers == 1}" @click="toggleAllMembers()">Show All Members</button></div>
            
            <div class="column">
                
                <select class="input" v-model="course_id" @change="getMemberCourses()" :disabld="this.working==true">
                        <option value="">Filter by course ...</option>
                        <option :value="row.id" v-for="row in courses" :key="row.id">{{ row.name }}</option>
                    </select>
                
            </div>
        </div>
        

        

       
        <div class="notification is-warning" v-if="loading == true">
            <span v-if="this.course_id > 0">Loading members ...</span>
            <span v-if="this.course_id == 0">Choose a course</span>
        </div>
        <div class="notification is-danger" v-if="loading == false && member_courses.length == 0">
            <span v-if="this.course_id > 0">There are no courses listed for any members matching your search criteria</span>
            <span v-if="this.course_id == 0">Choose a course</span>
        </div>

        <table class="table is-fullwidth is-hoverable is-size-7" v-if="loading == false && this.member_courses.length > 0">
            <thead>
                <tr>
                    <th width="50"></th>
                    <th>Forename</th>
                    <th>Surname</th>
                    <th>Training</th>
                    <th>Date Taken</th>
                    <th>Expiry Date</th>
                    <th>Status</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(row, index) in this.member_courses" :key="index" >
                    <td><router-link :to="'/members/' + row.member_id" target="_blank"><button class="button is-small is-link" >View</button></router-link></td>
                    <td>{{ row.forename }}</td>
                    <td>{{ row.surname}}</td>
                    <td>{{ row.name}}</td>
                    <td>{{ row.date }}</td>
                    <td>{{ row.expiry_date }}</td>
                    <td>
                        <span class="tag is-success" v-if="row.expiring_soon==0 && row.valid==1">Valid</span>
                        <span class="tag is-danger" v-if="row.expired==1">Expired</span>
                        <span class="tag is-warning" v-if="row.expiring_soon==1 && row.valid==1">Expiring</span>
                        <span class="tag is-link" v-if="row.no_date==1">Not Set</span>
                    </td>
                </tr>
                
                
            </tbody>
            
        </table>

       
    </div>
</template>

<script>
import UtilityService from '@/services/UtilityService.js';
import MemberMixin from '@/mixins/MemberMixin.js';

//import MemberGradeTags from '@/components/Generic/MemberGradeTags.vue';

export default {
    
      name: 'NisccReport',
        mixins: [MemberMixin],
        components: {
            //MemberGradeTags
        },
        data: function () {
            return {
                   search: '',
                   member_courses: [],
                   loading: false,
                    courses: [],
                   showModal: false,
                   order_by: "niscc_expiry ASC",
                   course_id: '',
                   showNursesOnly: 0,
                   showAllMembers: 0
            }
        },
        methods: {
            checkDate(checkDate) {
                if(checkDate != "" && checkDate != null)
                {
                    const sqlDateParts = checkDate.split('-');
                    
                    const sqlTimestamp = new Date(sqlDateParts[0], sqlDateParts[1] - 1, sqlDateParts[2]);
                    const targetTimestamp = new Date();

                    let isBeforeTargetDate = sqlTimestamp < targetTimestamp;
                    return isBeforeTargetDate
                }
                else{
                    return false
                }
            },
            async getCourses() {
                UtilityService.getSimpleApiData(this.accessToken, "courses").then(
                    response => this.courses = response 
                );
                        
            },
            async getMemberCourses() {
                if(this.course_id > 0)
                {
                this.loading = true;

                UtilityService.getSimpleApiData(this.accessToken, "member_courses?course_id=" + this.course_id + "&show_nurses_only=" + this.showNursesOnly + "&show_all_members=" + this.showAllMembers).then(
                        (member_courses => {
                            this.$set(this, "member_courses", member_courses);
                            this.loading = false
                            this.working = false
                    
                        }).bind(this)
                    );
                }
                
            },
            toggleShowNurses()
            {
                if(this.showNursesOnly == 1)
                {
                    this.showNursesOnly = 0
                }
                else {
                    this.showNursesOnly = 1
                }

                this.getMemberCourses()
            },
            toggleAllMembers()
            {
                if(this.showAllMembers == 1)
                {
                    this.showAllMembers = 0
                }
                else {
                    this.showAllMembers = 1
                }

                this.getMemberCourses()
            }
            
        },
        created() {
            this.getAccessToken()
            .then(() => { 
                this.getCourses();
                //this.getMemberCourses()
            });
        
        },
    }
</script>