<template>
    <div>
        <h2 class="title is-size-4">References</h2>
        <div class="notification is-warning" v-if="loading==true">Loading references ... </div>
        <div class="notification is-info" v-if="loading==false && references.length == 0">There are no reference details to show. </div>
        <table class="table is-fullwidth is-hoverable" v-if="loading==false && references.length > 0">
            <thead>
                <tr>
                    <th>Name</th>
                    <th>Position</th>
                    <th>Email</th>
                    <th>Notes</th>
                    <th>Sent</th>
                    <th>Returned</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="row in references" :key="row.id">
                    <td>{{ row.title}} {{ row.forename}} {{ row.surname}}</td>
                    <td>{{ row.position}}</td>
                    <td><a :href="'mailto:' + row.email" target="_blank">{{ row.email}}</a></td>
                    <td>{{ row.notes}}</td>
                    <td>
                        <span v-if="row.sent == 1">
                            {{ row.date_sent }}
                        </span>
                        <button v-else class="button is-link is-small" @click="markSent(row.id)">Mark as Sent</button>
                    </td>
                    <td>
                        <span v-if="row.returned == 1">
                            {{ row.date_returned }}
                        </span>
                        <button v-if="row.sent==1 && row.returned==0" class="button is-link is-small" @click="markReturned(row.id)">Mark as Returned</button>
                    </td>
                </tr>
                
            </tbody>
        </table>

       
    </div>
</template>

<script>
import MemberMixin from '@/mixins/MemberMixin.js';

import MemberService from '@/services/MemberService.js';
import UtilityService from '@/services/UtilityService.js';

export default {
    name: 'EmploymentHistory',
  data() {
    return {
      loading: true,
      working: false,
      
      references: [],
      
    }
  },
  mixins: [MemberMixin],
  props: ['member_id'],
  methods: {
        
       
        async getMemberReferences() {
            this.loading=true
            UtilityService.getSimpleApiData(this.accessToken, "members/" + this.member_id + "/references")
            .then(
                (references => {
                    this.$set(this, "references", references);
                    this.loading=false
                }).bind(this)
            );
        },
        async markSent( member_reference_id) {
			this.working = true

            if(confirm("Are you sure you want to mark this reference as sent ?"))
            {
            
                var formdata = { 
                    _method: 'patch',
                    sent : 1
                }; 

                MemberService.updateMemberReference(this.accessToken, formdata, this.member_id, member_reference_id)
                .then((response) => {
                    this.getMemberReferences()
                    console.log(response)
                    this.working = false
                    
                    }).catch((error) => {
                        if(error.response.status === 422)
                        {
                            console.log("Error:" + JSON.stringify(error.response.data));
                        }
                        alert("Something went wrong. Make sure you have filled in all the fields. \nOr ring David !!")
                        this.working = false
                });
            }
            else{
                this.working = false;
                return false
            }
        },
        async markReturned( member_reference_id) {
			this.working = true

            if(confirm("Are you sure you want to mark this reference as returned ?"))
            {
            
                var formdata = { 
                    _method: 'patch',
                    returned : 1
                }; 

                MemberService.updateMemberReference(this.accessToken, formdata, this.member_id, member_reference_id)
                .then((response) => {
                    this.getMemberReferences()
                    console.log(response)
                    this.working = false
                    
                    }).catch((error) => {
                        if(error.response.status === 422)
                        {
                            console.log("Error:" + JSON.stringify(error.response.data));
                        }
                        alert("Something went wrong. Make sure you have filled in all the fields. \nOr ring David !!")
                        this.working = false
                });
            }
            else{
                this.working = false;
                return false
            }
        },

      
        
    },
    created() {
            this.getAccessToken()
            .then(() => { 
                this.getMemberReferences()
                
            });
        
    },
    computed : {
       
    }
}
</script>