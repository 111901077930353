<template>
    <div>
        <div v-if="loading == true" class="notification is-warning">
            Loading details ...
        </div>
        
        <div v-if="loading == false">
            <div class="is-pulled-right">
                <img :src="this.member_image" alt="" style="height:60px;cursor:pointer" @click="selectedTab='image'"/>
                <br /><small @click="selectedTab='image'" style="font-size:0.7em;cursor:pointer">Update Image</small>
            </div>
            
            <div v-if="member.cleared ==0" class="mb-4"><button class="button is-danger">APPLICANT</button></div>

            <h3 class="title is-4"><p class="mb-3">{{ this.member.forename }} {{  this.member.surname }} </p>
                <button class="button is-warning is-small" @click="openSmsModal()" v-if="this.member.deceased==0">Send SMS</button>
                <button class="button is-info is-small ml-2" @click="openEmailModal()" v-if="this.member.deceased==0">Send Email</button>
                <button class="button is-danger is-small ml-2" v-if="member.deceased == 1 ">Deceased</button>
                <button class="button is-danger is-small ml-2" v-if="member.archived == 1 ">Archived</button>
                <button class="button is-danger is-small ml-2" v-if="member.blocked == 1 ">BLOCKED</button>
                <a :href="'https://balmoral-healthworks.co.uk/users/show/' + member.hw3_user_id" v-if="member.hw3_user_id > 0 " target="_blank"><button class="button is-success is-small ml-2" >View in HW3</button></a>
                
                <button class="button is-success is-small ml-2" v-if="member.niscc_valid == 1 ">NISCC Valid</button><button class="button is-danger is-small ml-2" v-if="member.niscc_valid == 0 ">NISCC Invalid</button>
                <button class="button is-success is-small ml-2" v-if="member.nmc_valid == 1 ">NMC Valid</button><button class="button is-danger is-small ml-2" v-if="member.nmc_valid == 0 ">NMC Invalid</button>
            
            </h3>

            <nav class="navbar" role="navigation" aria-label="main navigation" style="display:none">
                <div id="member_nav" class="navbar-menu">
                    <div class="navbar-start">
                        <a class="navbar-item" @click.prevent="selectedTab='personal'">Personal</a>
                        <a class="navbar-item" @click.prevent="selectedTab='notes'" >Notes</a>
                        <a class="navbar-item" @click.prevent="selectedTab='availability'" >Availability</a>

                       
                    </div>
                </div>
            </nav>
            
            <div class="tabs   is-toggle is-size-7 is-fullwidth" >
                <ul v-if="member.cleared==1">
                    <li :class="{'is-active' : selectedTab == 'personal'}"><a @click.prevent="selectedTab='personal'">Personal</a></li>
                    <li :class="{'is-active' : selectedTab == 'notes'}"><a @click.prevent="selectedTab='notes'">Notes</a></li>
                    <li :class="{'is-active' : selectedTab == 'availability'}" v-if="member.cleared ==1"><a @click.prevent="selectedTab='availability'">Availability</a></li>
                    <li :class="{'is-active' : selectedTab == 'bookings'}"><a @click.prevent="selectedTab='bookings'">Bookings</a></li>
                    <li :class="{'is-active' : selectedTab == 'exclusions'}" v-if="member.cleared ==1"><a @click.prevent="selectedTab='exclusions'">Exclusions</a></li>
                    <li :class="{'is-active' : selectedTab == 'emergency_contacts'}"><a @click.prevent="selectedTab='emergency_contacts'">NOK</a></li>
                    <li :class="{'is-active' : selectedTab == 'grades'}"><a @click.prevent="selectedTab='grades'">Grades</a></li>
                    <li :class="{'is-active' : selectedTab == 'training'}"><a @click.prevent="selectedTab='training'">Training</a></li>
                    <li :class="{'is-active' : selectedTab == 'accessni'}" style=""><a @click.prevent="selectedTab='accessni'">Access NI</a></li>
                    <li :class="{'is-active' : selectedTab == 'niscc'}" v-if="member.requires_niscc==1"><a @click.prevent="selectedTab='niscc'">NISCC</a></li>
                    <li :class="{'is-active' : selectedTab == 'nmc'}" v-if="member.requires_nmc==1"><a @click.prevent="selectedTab='nmc'">NMC</a></li>
                    <li :class="{'is-active' : selectedTab == 'hcpc'}" v-if="member.requires_hcpc==1"><a @click.prevent="selectedTab='hcpc'">HCPC</a></li>
                    <li :class="{'is-active' : selectedTab == 'cop'}" v-if="member.requires_cop == 1"><a @click.prevent="selectedTab='cop'">COP</a></li>
                    <li :class="{'is-active' : selectedTab == 'skills_sheet'}" title="Clinical Competency Self-Assessment"><a @click.prevent="selectedTab='skills_sheet'">Competency</a></li>
                    <li :class="{'is-active' : selectedTab == 'health'}"><a @click.prevent="selectedTab='health'">Health</a></li>
                    <li :class="{'is-active' : selectedTab == 'education'}" style="display:none"><a @click.prevent="selectedTab='education'"  >Education (OLD)</a></li>
                    <li :class="{'is-active' : selectedTab == 'work_experience'}" style="display:none"><a @click.prevent="selectedTab='work_experience'">Work Exp. (OLD)</a></li>
                    <li :class="{'is-active' : selectedTab == 'employment_history'}"><a @click.prevent="selectedTab='employment_history'">Employment</a></li>
                    <li :class="{'is-active' : selectedTab == 'union'}"><a @click.prevent="selectedTab='union'">Union</a></li>
                    <li :class="{'is-active' : selectedTab == 'documents'}" style="display:none"><a @click.prevent="selectedTab='documents'">Docs</a></li>
                    <li :class="{'is-active' : selectedTab == 'profile'}" style="display:none"><a @click.prevent="selectedTab='profile'">Profiling</a></li>
                    <li :class="{'is-active' : selectedTab == 'actions'}"><a @click.prevent="selectedTab='actions'">Actions</a></li>
                    <li :class="{'is-active' : selectedTab == 'log'}"><a @click.prevent="selectedTab='log'">Logs</a></li>
                </ul>
                <ul v-if="member.cleared==0">
                    <li :class="{'is-active' : selectedTab == 'application'}"><a @click.prevent="selectedTab='application'">Application</a></li>
                    <li :class="{'is-active' : selectedTab == 'personal'}" class=""><a class="" @click.prevent="selectedTab='personal'">Personal</a></li>
                    <li :class="{'is-active' : selectedTab == 'notes'}"><a @click.prevent="selectedTab='notes'">Notes</a></li>
                    <li :class="{'is-active' : selectedTab == 'emergency_contacts'}"><a @click.prevent="selectedTab='emergency_contacts'">NOK</a></li>
                    <li :class="{'is-active' : selectedTab == 'grades'}"><a @click.prevent="selectedTab='grades'">Grades</a></li>
                    <li :class="{'is-active' : selectedTab == 'training'}"><a @click.prevent="selectedTab='training'">Training</a></li>
                    <li :class="{'is-active' : selectedTab == 'accessni'}" style=""><a @click.prevent="selectedTab='accessni'">Access NI</a></li>
                    <li :class="{'is-active' : selectedTab == 'niscc'}" v-if="member.requires_niscc==1"><a @click.prevent="selectedTab='niscc'">NISCC</a></li>
                    <li :class="{'is-active' : selectedTab == 'nmc'}" v-if="member.requires_nmc==1"><a @click.prevent="selectedTab='nmc'">NMC</a></li>
                    <li :class="{'is-active' : selectedTab == 'hcpc'}"  v-if="member.requires_hcpc==1"><a @click.prevent="selectedTab='hcpc'">HCPC</a></li>
                    <li :class="{'is-active' : selectedTab == 'cop'}" v-if="member.requires_cop == 1"><a @click.prevent="selectedTab='cop'">COP</a></li>
                    <li :class="{'is-active' : selectedTab == 'skills_sheet'}" title="Clinical Competency Self-Assessment"><a @click.prevent="selectedTab='skills_sheet'">Competency</a></li>
                    <li :class="{'is-active' : selectedTab == 'health'}"><a @click.prevent="selectedTab='health'">Health</a></li>
                    <li :class="{'is-active' : selectedTab == 'education'}" class=" "  style="display:none"><a class="" @click.prevent="selectedTab='education'">Education (OLD)</a></li>
                    <li :class="{'is-active' : selectedTab == 'work_experience'}"  style="display:none"><a @click.prevent="selectedTab='work_experience'">Work Exp. (OLD)</a></li>
                    <li :class="{'is-active' : selectedTab == 'employment_history'}"><a @click.prevent="selectedTab='employment_history'">Employment</a></li>
                    <li :class="{'is-active' : selectedTab == 'union'}"><a @click.prevent="selectedTab='union'">Union</a></li>
                    <li :class="{'is-active' : selectedTab == 'references'}"><a @click.prevent="selectedTab='references'">References</a></li>
                    <li :class="{'is-active' : selectedTab == 'interview'}" style=""><a @click.prevent="selectedTab='interview'">Interview</a></li>
                    <li :class="{'is-active' : selectedTab == 'documents'}" style="display:none"><a @click.prevent="selectedTab='documents'">Docs</a></li>
                    <li :class="{'is-active' : selectedTab == 'actions'}"><a @click.prevent="selectedTab='actions'">Actions</a></li>
                    <li :class="{'is-active' : selectedTab == 'log'}"><a @click.prevent="selectedTab='log'">Logs</a></li>
                </ul>
            </div>

            <div v-if="selectedTab=='application'">
                <MemberApplication :member="this.member" :member_application_id="this.member.member_application_id" />
            </div>

            <div v-if="selectedTab=='personal'">
                <MemberPersonal :member="this.member" />
            </div>

            <!--Notes-->
            <div v-if="selectedTab=='notes'">
                <MemberNotes :member_id="this.member.id" />
            </div>

            <div v-if="selectedTab=='availability'">
                <MemberAvailability :member_id="this.member.id" />
            </div>

            <div v-if="selectedTab=='grades'">
                <MemberGrades :member_id="this.member.id" />
            </div>

            <div v-if="selectedTab=='exclusions'">
                <MemberExclusions :member_id="this.member.id" />
            </div>

            <div v-if="selectedTab=='documents'">
                <MemberDocuments :member_id="this.member.id" />
            </div>

            <div v-if="selectedTab=='emergency_contacts'">
                <MemberEmergencyContacts :member_id="this.member.id" />
            </div>

           
            <!--Profile-->
            <div v-if="selectedTab=='profile'">
                <MemberProfile />
            </div>

            <!--Bookings-->
            <div v-if="selectedTab=='bookings'">
                <MemberBookings :member_id="this.member.id" />
            </div>

            <!--Training-->
            <div v-if="selectedTab=='training'">
                <MemberTraining />
            </div>

            <!--Education-->
            <div v-if="selectedTab=='education'">
                <MemberEducation :member_id="this.member.id"/>
            </div>

            <div v-if="selectedTab=='work_experience'">
                <MemberWorkExperience :member_id="this.member.id"/>
            </div>

            <div v-if="selectedTab=='employment_history'">
                <MemberEmploymentHistory :member_id="this.member.id"/>
            </div>

            <div v-if="selectedTab=='references'">
                <MemberReferences :member_id="this.member.id"/>
            </div>

            <div v-if="selectedTab=='union'">
                <MemberUnion :member="this.member" @reload-member="getMember"/>
            </div>

            <div v-if="selectedTab=='skills_sheet'">
                <MemberSkillsSheet :member_id="this.member.id" :requires_cop="this.member.requires_cop" :requires_nmc="this.member.requires_nmc" :requires_niscc="this.member.requires_niscc"/>
            </div>

            <div v-if="selectedTab=='health'">
                <MemberHealth :member_id="this.member.id"/>
            </div>

            <div v-if="selectedTab=='accessni'">
                <MemberAccessNi :member_id="this.member.id" @reload-member="getMember"/>
            </div>

            <div v-if="selectedTab=='niscc'">
                <MemberNiscc :member="this.member" @reload-member="getMember"/>
            </div>

            <div v-if="selectedTab=='nmc'" >
                <MemberNmc :member="this.member" @reload-member="getMember"/>
            </div>

            <div v-if="selectedTab=='hcpc'" >
                <MemberHcpc :member="this.member" @reload-member="getMember"/>
            </div>

            <div v-if="selectedTab=='cop'" >
                <MemberCop :member="this.member" @reload-member="getMember"/>
            </div>

            <div v-if="selectedTab=='interview'">
                <MemberInterview :member_id="this.member.id" :member_application_id="this.member.member_application_id" @reload-member="getMember"/>
            </div>

             <div v-if="selectedTab=='actions'">
                <MemberActions :member="this.member" @reload-member="getMember"/>
            </div>

            <!--Log-->
            <div v-if="selectedTab=='log'">
                <MemberLog :member_id="this.member.id"/>
            </div>

            <div v-if="selectedTab=='image'">
                <MemberImage :member="this.member" :member_image="this.member_image" @reload-member="getMember"/>
            </div>
        </div>

        <SmsModal :member_id="this.member.id" :showSmsModal="showSmsModal" @close="closeSmsModal"/>

        <EmailModal :member_id="this.member.id" :showEmailModal="showEmailModal" @close="closeEmailModal" :emailText="emailText" :emailSubject="emailSubject"/>

        
        
    </div>

</template>

<script>
    import MemberService from '@/services/MemberService.js';
    import UtilityService from '@/services/UtilityService.js';

    import MemberPersonal from './MemberPersonal.vue'
    import MemberApplication from './MemberApplication.vue'
    import MemberGrades from './MemberGrades.vue'
    import MemberDocuments from './MemberDocuments.vue'
    import MemberBookings from './MemberBookings.vue'
    import MemberProfile from './MemberProfile.vue'
    import MemberLog from './MemberLog.vue'
    import MemberTraining from './MemberTraining.vue'
    import MemberActions from './MemberActions.vue'
    import MemberNmc from './MemberNmc.vue'
    import MemberAccessNi from './MemberAccessNi.vue'
    import MemberNiscc from './MemberNiscc.vue'
    import MemberHcpc from './MemberHcpc.vue'
    import MemberCop from './MemberCop.vue'
    import MemberAvailability from './MemberAvailability.vue'
    import MemberNotes from './MemberNotes.vue'
    import MemberEmergencyContacts from './MemberEmergencyContacts.vue'
    import MemberExclusions from './MemberExclusions.vue'
    import MemberEducation from './MemberEducation.vue'
    import MemberWorkExperience from './MemberWorkExperience.vue'
    import MemberEmploymentHistory from './MemberEmploymentHistory.vue'
    import MemberUnion from './MemberUnion.vue'
    import MemberSkillsSheet from './MemberSkillsSheet.vue'
    import MemberHealth from './MemberHealth.vue'
    import MemberInterview from './MemberInterview.vue'
    import MemberImage from './MemberImage.vue'
      import MemberReferences from './MemberReferences.vue'
    
    import SmsModal from './SmsModal.vue'
    import EmailModal from './EmailModal.vue'

    import MemberMixin from '@/mixins/MemberMixin.js';
    
    export default {
      name: 'ViewMember',
      components: {
        MemberPersonal,
        MemberApplication,
        MemberGrades,
        MemberDocuments,
        MemberBookings,
        MemberProfile,
        MemberLog,
        MemberTraining,
        MemberActions,
        MemberNmc,
        MemberAccessNi,
        MemberNiscc,
        MemberAvailability,
        MemberNotes,
        MemberHcpc,
        MemberCop,
        MemberEmergencyContacts,
        MemberReferences,
        MemberExclusions,
        MemberEducation,
        MemberWorkExperience,
        MemberEmploymentHistory,
        MemberUnion,
        MemberInterview,
        SmsModal,
        EmailModal,
        MemberSkillsSheet,
        MemberHealth,
        MemberImage
      },
        mixins: [MemberMixin],
        data: function () {
            return {
                   loading : true,
                  search: "",
                    API_NAME: process.env.VUE_APP_API_NAME,
                    accessToken: '',
                    selectedTab : 'personal',
                    member: [],
                    member_image: '',
                    showSmsModal: false,
                    showEmailModal: false,
                    emailText: '',
                    emailSubject: ''
            }
        },
        methods: {
            openSmsModal() {
                this.showSmsModal = true
            },
            openEmailModal() {
                this.showEmailModal = true
            },
            async getMember() {
                this.loading = true;

                    MemberService.getMember(this.accessToken, this.$route.params.id)
                    .then(
                        (member => {
                            this.$set(this, "member", member);
                            this.loading = false
                            this.getMemberImage()

                            if(this.member.cleared == 0)
                            {
                                this.selectedTab = 'application'
                            }
                        this.loading=false
                        }).bind(this)
                    );
                
            },
            async getMemberImage() {
                UtilityService.getSimpleApiData(this.accessToken, "members/" + this.$route.params.id + "/image_base64")
                .then(
                    (member_image => {
                        this.$set(this, "member_image", member_image);
                    }).bind(this)
                );
            },
           
            
        },
        created() {
            this.getAccessToken()
            .then(() => { 
                this.getMember();

                if(this.$route.query.tab != "" && this.$route.query.tab != undefined)
                {
                    this.selectedTab = this.$route.query.tab;
                }
            });
        
        },
        computed : {
            
        },
    }
</script>